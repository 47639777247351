<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Aws from "@/services/Aws";
import Swal from "sweetalert2";

export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "AWS Instances",
            error: null,
            tableData: [],
            currentPage: 1,
            perPage: 10,
            items: [
                {
                    text: "AWS",
                },
                {
                    text: "AWS Instances",
                    active: true,
                },
            ] ,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "InstanceId",
            sortDesc: true,
            isBusy: false,
            filterInstanceStatus: 0,
            filterInstanceStarred: 0,
            fields: [
                {
                    key: "InstanceId",
                    label: "Instance Id",
                    sortable: false,
                },
                {
                    key: "InstanceName",
                    label: "Instance Name",
                    sortable: true,
                },
                {
                    key: "InstanceType",
                    label: "Instance Type",
                    sortable: false,
                },
                {
                    key: "PublicIpAddress",
                    label: "Public Ip",
                    sortable: false,
                },
                {
                    key: "State",
                    label: "State",
                    sortable: true,
                },
                {
                    key: "instanceTargetGroups",
                    label: "Target Groups",
                    sortable: false,
                },
                {
                    key: "note",
                    label: "Note",
                    sortable: false,
                },
                "action",
            ],
            totalRows: 0,
            showModal: false
        };
    },
    async mounted() {
        await this.getInstances()
    },
    methods: {
        async getInstances() {
            try {
                this.isBusy = true;
                const response = await Aws.getInstances();
                this.originalTableData = response.data.data;
                this.tableData = [...this.originalTableData];
                this.totalRows = this.tableData.length;
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.showLoader = false;
                this.isBusy = false;
            }
        },
        getTagName(tags) {
            // Find the tag with the Key "Name"
            const nameTag = tags.find(tag => tag.Key === "Name");
            return nameTag ? nameTag.Value : "";  // Return the value or a fallback
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        applyFilters() {
            let filteredData = [...this.originalTableData];

            // Apply "Only Running Instances" filter if active
            if (this.filterInstanceStatus) {
                filteredData = filteredData.filter(item => item.State === 'running');
            }

            // Apply "Only Favourite Instances" filter if active
            if (this.filterInstanceStarred) {
                filteredData = filteredData.filter(item => item.starred === 1);
            }

            this.tableData = filteredData;
            this.totalRows = this.tableData.length;
        },

        async changeStarredStatus(InstanceId, starred) {
            const newStarredStatus = starred === 1 ? 0 : 1;
            const instance = this.originalTableData.find(item => item.InstanceId === InstanceId);
            const originalStarredStatus = instance ? instance.starred : null;

            if (instance) {
                instance.starred = newStarredStatus;
            }

            try {
                const response = await Aws.updateInstance(InstanceId, {
                    starred: newStarredStatus,
                    csrf_token: this.csrf_token
                });

                if (response.data.data) {
                    this.applyFilters();
                } else {
                    if (instance) {
                        instance.starred = originalStarredStatus;
                    }
                }
            } catch (error) {
                if (instance) {
                    instance.starred = originalStarredStatus;
                }
                this.error = error.response?.data?.error || "An unknown error occurred";
                this.failedmsg("Changes not saved", this.error);
            }
        },

        startInstance(instanceId){
            Swal.fire({
                title: "Start Instance <br>" + instanceId,
                icon: "warning",
                html: `
                        <p>Type "Start" to confirm:</p>
                        <input type="text" id="startConfirmation" class="swal2-input" autocomplete="off">
                    `,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Start",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const confirmationText = Swal.getPopup().querySelector(
                        "#startConfirmation"
                    ).value;
                    if (confirmationText.toLowerCase() !== "start") {
                        Swal.showValidationMessage('You must type "Start" to confirm');
                        return false;
                    }
                    return Aws.startInstance(instanceId)
                        .then((response) => {
                            return response;
                        })
                        .catch((error) => {
                            let errorMsg = error.response.data.error
                                ? error.response.data.error
                                : "";
                            this.failedmsg("Fail!", errorMsg);
                        });
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const res = result.value.data ? result.value.data.success : false;
                    const error = result.value.data.error
                        ? result.value.data.error
                        : "Failed";

                    if (res) {
                        this.successmsg('Start Instance', 'Instance is successfully started')
                        setTimeout(() => {
                            this.getInstances();
                        }, 2000);
                    } else {
                        this.warningmsg("Fail!", error);
                    }
                }
            });

        },

        stopInstance(instanceId){

            Swal.fire({
                title: "Stop Instance <br>" + instanceId,
                icon: "warning",
                html: `
                        <p>Type "Stop" to confirm:</p>
                        <input type="text" id="stopConfirmation" class="swal2-input" autocomplete="off">
                    `,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Stop",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const confirmationText = Swal.getPopup().querySelector(
                        "#stopConfirmation"
                    ).value;
                    if (confirmationText.toLowerCase() !== "stop") {
                        Swal.showValidationMessage('You must type "Stop" to confirm');
                        return false;
                    }
                    return Aws.stopInstance(instanceId)
                        .then((response) => {
                            return response;
                        })
                        .catch((error) => {
                            let errorMsg = error.response.data.error
                                ? error.response.data.error
                                : "";
                            this.failedmsg("Fail!", errorMsg);
                        });
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const res = result.value.data ? result.value.data.success : false;
                    const error = result.value.data.error
                        ? result.value.data.error
                        : "Failed";

                    if (res) {
                        this.successmsg('Stop Instance', 'Instance is successfully stopped')
                        setTimeout(() => {
                            this.getInstances();
                        }, 2000);
                    } else {
                        this.warningmsg("Fail!", error);
                    }
                }
            });
        },
    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Total Instances: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-3">
                                <div class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>


                            </div>
                            <div class="col-md-3">
                                <b-form-checkbox-group>
                                    <div class="form-check form-switch form-switch-lg mb-3">
                                        <input v-model="filterInstanceStatus" @change="applyFilters"
                                               type="checkbox"
                                               class="form-check-input"
                                               id="filterInstanceStatus"
                                        />
                                        <label class="form-check-label" for="filterInstanceStatus">Only Running Instances</label>
                                    </div>
                                </b-form-checkbox-group>
                            </div>

                            <div class="col-md-3">
                                <b-form-checkbox-group>
                                    <div class="form-check form-switch form-switch-lg mb-3">
                                        <input v-model="filterInstanceStarred" @change="applyFilters"
                                               type="checkbox"
                                               class="form-check-input"
                                               id="filterInstanceStarred"
                                        />
                                        <label class="form-check-label" for="filterInstanceStarred">Only Favourite Instances</label>
                                    </div>
                                </b-form-checkbox-group>
                            </div>

                            <!-- Search -->
                            <div class="col-sm-12 col-md-3">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(InstanceName)="data">
                                   <div>{{getTagName(data.item.Tags)}}</div>
                                </template>

                                <template v-slot:cell(State)="data">
                                    <div class="font-size-16" v-html="format_status(data.item.State)"></div>
                                </template>
                                <template v-slot:cell(instanceTargetGroups)="data">
                                    {{ data.item.instanceTargetGroups.join(', ') }}
                                </template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <router-link title="View Instance Details" :to="{ path: `/aws/instances/${data.item.InstanceId}`}">
                                                <i class="uil uil-eye font-size-18"></i>
                                            </router-link>
                                        </li>
                                        <li class="list-inline-item" v-if="data.item.State == 'stopped' && data.item.stateChange">
                                            <a href="#" class="link-success" @click="startInstance(data.item.InstanceId)" title="Start Instance">
                                                <i class="uil uil-play-circle font-size-18 text-success"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" v-if="data.item.State == 'running' && data.item.stateChange">
                                            <a href="#" class="link-danger"
                                               @click="stopInstance(data.item.InstanceId)"
                                               title="Stop Instance"
                                               :class="{
                                                  'disabled': data.item.instanceTargetGroups.includes('APIv3'),
                                               }"
                                            >
                                                <i class="uil uil-stop-circle font-size-18 text-danger"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="#" class="link-success"
                                               @click="getConnectionString(data.item.PublicDnsName, data.item.awsEc2DefaultConnectionUser)"
                                               title="Copy Connection String"
                                               :class="{
                                                  'disabled': !data.item.PublicDnsName,
                                               }"
                                            >
                                                <i class="mdi mdi-lan-connect font-size-18 text-primary"></i>
                                            </a>
                                        </li>

                                        <li class="list-inline-item" >
                                            <a href="#" class="link-success" @click="changeStarredStatus(data.item.InstanceId, data.item.starred)" title="Set/Unset Favourite">
                                                <i class="mdi font-size-18 text-gold"
                                                   :class="[data.item.starred === 1 ? 'mdi-star' : 'mdi-star-outline']"
                                                ></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
